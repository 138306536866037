<template>
    <ts-panel>
        <ts-panel-wrapper>
            <div class="tw-flex tw-space-x-3">
                <TsImageInput
                    :file="model.photo"
                    @input="file => (model.photo = file)"
                    :height="225"
                    :width="175"
                />
                <div class="tw-w-full tw-space-y-2">
                    <div class="row">
                        <div class="col-md-12 tw-space-y-1">
                            <label>{{ $t('employeeProfile.nameKh') }}</label>
                            <input
                                v-model.trim="model.employee_name_kh"
                                type="text"
                                class="form-control"
                                :placeholder="$t('employeeProfile.nameKh')"
                            />
                            <div
                                class="tw-text-red-600 tw-text-xs"
                                v-if="errors.has('employee_name_kh')"
                            >
                                {{ errors.first('employee_name_kh') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 tw-space-y-1">
                            <label class="required">{{
                                $t('employeeProfile.nameEn')
                            }}</label>
                            <input
                                v-model.trim="model.employee_name_en"
                                type="text"
                                class="form-control"
                                :placeholder="$t('employeeProfile.nameEn')"
                            />
                            <div
                                class="tw-text-red-600 tw-text-xs"
                                v-if="errors.has('employee_name_en')"
                            >
                                {{ errors.first('employee_name_en') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 tw-space-y-1">
                            <label class="required tw-mr-2 tw-leading-t">{{
                                $t('employeeProfile.sex')
                            }}</label>
                            <a-radio-group
                                name="sex"
                                v-model="model.sex"
                                class="tw-ml-3"
                            >
                                <a-radio value="male">
                                    {{ $t('employeeProfile.male') }}
                                </a-radio>
                                <a-radio value="female">
                                    {{ $t('employeeProfile.female') }}
                                </a-radio>
                            </a-radio-group>
                            <div
                                class="tw-text-red-600 tw-text-xs"
                                v-if="errors.has('sex')"
                            >
                                {{ errors.first('sex') }}
                            </div>
                        </div>
                    </div>
                    <div class="row tw-mt-0">
                        <div class="col-md-12 tw-space-y-1">
                            <label class="required">{{
                                $t('employeeProfile.dateOfBirth')
                            }}</label>
                            <a-date-picker
                                v-model="model.date_of_birth"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                :placeholder="$t('employeeProfile.dateOfBirth')"
                                class="tw-w-full"
                            ></a-date-picker>
                            <div
                                class="tw-text-red-600 tw-text-xs"
                                v-if="errors.has('date_of_birth')"
                            >
                                {{ errors.first('date_of_birth') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from 'form-backend-validation'
export default {
    name: 'photoName',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {}
        }
    },
    created () {
        this.model = this.value
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
